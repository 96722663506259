<template>
  <div>
    <div v-if="lang == 'ka'">
      <vue-headful
        :description="[
          removeHTMLForTitle(properties.internationalTitleKA),
          removeHTMLForTitle(properties.ClinicAndPharmacyGroupKA),
          removeHTMLForTitle(properties.ownerCompanyKA),
          removeHTMLForTitle(properties.ownerCountryKA),
          removeHTMLForTitle(properties.ReleaseFormKA),
          removeHTMLForTitle(properties.issuanceKA),
        ]"
        :keywords="item.keywords.toString()"
        image=""
        :title="[removeHTMLForTitle(item.titleKA)]"
        lang=""
        ogLocale=""
        url=""
      />
    </div>
    <div v-else>
      <vue-headful
        :description="[
          removeHTMLForTitle(properties.internationalTitleEN),
          removeHTMLForTitle(properties.ClinicAndPharmacyGroupEN),
          removeHTMLForTitle(properties.ownerCompanyEN),
          removeHTMLForTitle(properties.ownerCountryEN),
          removeHTMLForTitle(properties.ReleaseFormEN),
          removeHTMLForTitle(properties.issuanceEN),
        ]"
        :keywords="item.keywords.toString()"
        image=""
        :title="[removeHTMLForTitle(singleNews.item.titleEN)]"
        lang=""
        ogLocale=""
        url=""
      />
    </div>
    <div class="medicamentsTable pr-0">
      <div class="tableContainer pl-0 pr-0">
        <div style="background-color: #f6f4f5; overflow-x: auto">
          <h4
            class="test pt-3"
            v-if="lang == 'ka'"
            v-html="item.titleKA"
            style="background-color: #f6f4f5; font-family: mtavruliBOLD"
          >
            {{ item.titleKA }}
          </h4>
          <h4
            class="test"
            v-else
            v-html="item.titleEN"
            style="
              background-color: #f6f4f5;
              font-family: mtavruliBOLD;
              text-transform: uppercase;
            "
          >
            {{ item.titleEN }}
          </h4>

          <table>
            <tr>
              <th class="test">
                {{
                  $t(
                    "medicamentsDescription.medicamentsTable.International name"
                  )
                }}
              </th>
              <td
                v-if="lang == 'ka'"
                class=""
                v-html="properties.internationalTitleKA"
              >
                {{ properties.internationalTitleKA }}
              </td>
              <td v-html="properties.internationalTitleEN" v-else class="">
                {{ properties.internationalTitleEN }}
              </td>
            </tr>
            <tr>
              <th class="test">
                {{
                  $t(
                    "medicamentsDescription.medicamentsTable.Clinical-pharmacologicalp"
                  )
                }}
              </th>
              <td
                v-if="lang == 'ka'"
                class=""
                v-html="properties.ClinicAndPharmacyGroupKA"
              >
                {{ properties.ClinicAndPharmacyGroupKA }}
              </td>
              <td v-else class="" v-html="properties.ClinicAndPharmacyGroupEN">
                {{ properties.ClinicAndPharmacyGroupEN }}
              </td>
            </tr>
            <tr>
              <th class="test">
                {{
                  $t(
                    "medicamentsDescription.medicamentsTable.PharmaceuticalCompany"
                  )
                }}
              </th>
              <td
                v-if="lang == 'ka'"
                class=""
                v-html="properties.ownerCompanyKA"
              >
                {{ properties.ownerCompanyKA }}
              </td>
              <td v-else class="" v-html="properties.ownerCompanyEN">
                {{ properties.ownerCompanyEN }}
              </td>
            </tr>
            <tr>
              <th class="test">
                {{
                  $t("medicamentsDescription.medicamentsTable. ProducedCountry")
                }}
              </th>
              <td
                v-if="lang == 'ka'"
                class=""
                v-html="properties.ownerCountryKA"
              >
                {{ properties.ownerCountryKA }}
              </td>
              <td v-else class="" v-html="properties.ownerCountryEN">
                {{ properties.ownerCountryEN }}
              </td>
            </tr>
            <tr>
              <th class="test">
                {{ $t("medicamentsDescription.medicamentsTable.ReleaseForm") }}
              </th>
              <td v-if="lang == 'ka'" v-html="properties.ReleaseFormKA">
                {{ properties.ReleaseFormKA }}
              </td>
              <td v-else v-html="properties.ReleaseFormEN">
                {{ properties.ReleaseFormEN }}
              </td>
            </tr>
            <tr>
              <th class="test">
                {{ $t("medicamentsDescription.medicamentsTable.IssuanceMode") }}
              </th>
              <td v-if="lang == 'ka'" class="" v-html="properties.issuanceKA">
                {{ properties.issuanceKA }}
              </td>
              <td v-else class="" v-html="properties.issuanceEN">
                {{ properties.issuanceEN }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: "ka",
      singlePorduct: null,
    };
  },
  props: ["properties", "item"],
  methods: {
    removeHTMLForTitle: function (string) {
      return string.replace(/<\/?[^>]+>/gi, "");
    },
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.test >>> * {
  font-family: mtavruliBOLD;
}
.medicament {
  width: 100%;
  max-width: 1400px;
}

.medicamentName {
  margin-top: 20px;
  margin-bottom: 50px;
  color: #7f5496;
  margin-top: 20px;
  margin-bottom: -40px;

  display: flex;
  text-align: center;
  align-items: center;
}

.medicamentName h2 {
  font-weight: bold;
  font-family: mtavruli !important;
}

.tableContainer h4 {
  color: #7f5496;
}

::v-deep.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.tableContainer {
  border-radius: 5px;

  color: #383030;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;
  background-color: #f6f4f5;
}

td,
th {
  text-align: left;
  padding: 8px;
  padding-left: 2px;
  color: #4d4d4c;
}

tr:nth-child(even) {
  background-color: #a480b769;
}
tr:nth-child(odd) {
  background-color: #f6f4f5;
}
.zoom:after {
  display: none !important;
}

.zoom img:hover {
  width: 450px !important;
  height: 450px !important;
}
:v-deep .zoomImg img.zoomImg {
  width: 10px !important;
}

@media all and (max-width: 1500px) {
  .medicament {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media all and (max-width: 1199px) {
  .medicamentsTable {
    margin-top: 20px !important;
  }
}

@media all and (max-width: 413px) {
  ::v-deep.lingalleryContainer .lingallery figure img {
    border: 2px solid red !important;
  }
}
</style>
