<template>
  <div>
    <div class="modalStyle">
      <input
        type="text"
        v-model="search"
        ref="inputSearch"
        style="
          width: 100%;
          height: 40px;
          border-radius: 0px !important;
          border: 2px solid #b3b3b3;
          padding-left: 5px;
          font-size: 13px;
        "
        placeholder="..."
      />
      <div v-if="isLoading" class="centeringSearch">
        <div v-if="search != ''" class="mt-2">
          <b-skeleton-img no-aspect height="9rem" type="input"></b-skeleton-img>
        </div>
      </div>
      <div v-else>
        <div style="width: 100%">
          <div class="resultCont" v-if="total >= 0">
            <p class="result mt-3">
              {{ $t("front.searchResult") }} <span>{{ total }}</span>
            </p>
          </div>
        </div>
        <div v-if="!isLoading && items" class="modalContains">
          <div
            v-for="item in items"
            :key="item.slug"
            class="d-flex"
            @click="handleNavigate(item.slug)"
          >
            <div class="mt-2" style="height: 160px; width: 200px">
              <img
                v-if="item.images"
                :src="`${server_url}/${item.images[0].image}`"
                :alt="
                  lang == 'ka'
                    ? removeHTML(item.titleKA)
                    : removeHTML(item.titleEN)
                "
                class="img-fluid"
                style="
                  height: 100%;
                  width: 100%;
                  border-radius: 0px;
                  background-position: center center;

                  background-repeat: no-repeat;
                  background-attachment: fixed;
                  background-position: 90% 90%;
                "
              />
            </div>
            <div>
              <p
                :style="`
                          font-family: mtavruli;
                          color: #7f5496;
                          font-size: 14px;
                          margin-left: 25px;
                          margin-top: 10px;
                          cursor: pointer
                      
                      
                        `"
              >
                <router-link
                  class="
                    btnArchive
                    justify-content-center
                    d-flex
                    align-items-center
                  "
                  style="text-decoration: none; padding: 70px 0"
                  :to="
                    '/' +
                    item.category.titleEN +
                    '/' +
                    item.slug +
                    '/medicamentsDetail'
                  "
                >
                  <span
                    style="font-family: mtavruliBOLD; color: #7f5496"
                    class="headerTitle"
                    v-if="lang == 'ka'"
                    v-html="item.titleKA"
                    >{{ item.titleKA }}</span
                  >
                  <span
                    class="headerTitle"
                    style="font-family: mtavruliBOLD; color: #7f5496"
                    v-html="item.titleEN"
                    v-else
                    >{{ item.titleEN }}</span
                  >
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <div v-else class="mt-2">
          <b-skeleton-img no-aspect height="9rem" type="input"></b-skeleton-img>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import env from "../../../../env.json";
import axios from "axios";

export default {
  name: "DetailModalCsr",
  data() {
    return {
      category: "medicaments",
      isLoading: false,
      total: 0,
      search: "",
      timer: null,
      lang: "ka",
      singlePorduct: null,
      server_url: env.server_url,
      items: [],
      timeout: 500,
    };
  },
  watch: {
    search: function (val) {
      clearTimeout(this.timer);
      if (val) {
        this.timer = setTimeout(() => {
          this.isLoading = true;
          const valuedUrl = `${env.host}/search/products/all/null/${val}/null/null`;
          axios.get(valuedUrl).then((result) => {
            this.items = [...result.data.item];
            this.total = result.data.item.length;
            this.isLoading = false;
          });
        }, this.timeout);
      } else {
        this.total = 0;
      }
    },
  },
  methods: {
    removeHTML: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
    handleNavigate: function (slug) {
      this.isLoading = true;
      axios
        .get(`${env.host}/get/once/products/${slug}/null/null`)
        .then((result) => {
          this.singlePorduct = {
            item: result.data.item,
          };
          this.isLoading = false;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.inputSearch.focus();
    }, 100);
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.headerTitle >>> * {
  font-family: mtavruli;
}
.btn-secondary {
  color: #fff;
  background-color: #a480b7 !important;
  border-color: #a480b7 !important;
  border-radius: 0px !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #7e549374;
}
::v-deep.btn-secondary {
  border-radius: 0px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}
.modalStyle {
  width: 100%;
}

.modalContains {
  overflow-y: scroll;
  max-height: 300px !important;
}
</style>