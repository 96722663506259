<template>
  <div class="nav-fill" v-if="properties">
    <b-card no-body>
      <b-tabs card>
        <b-tab :title="$t('medicamentsDescription.productDescribe')" active>
          <b-card-text
            v-if="lang == 'ka'"
            v-html="properties.productDescriptionKA"
          ></b-card-text>
          <b-card-text
            v-else
            v-html="properties.productDescriptionKA"
          ></b-card-text>
        </b-tab>
        <b-tab :title="$t('medicamentsDescription.effects')">
          <b-card-text
            v-if="lang == 'ka'"
            v-html="properties.displayKA"
          ></b-card-text>
          <b-card-text v-else v-html="properties.displayEN"></b-card-text>
        </b-tab>

        <b-tab :title="$t('medicamentsDescription.productDosage')">
          <b-card-text
            v-if="lang == 'ka'"
            v-html="properties.compositionKA"
          ></b-card-text>
          <b-card-text v-else v-html="properties.compositionEN"></b-card-text>
        </b-tab>
        <b-tab :title="$t('medicamentsDescription.sideEFfects')">
          <b-card-text
            v-if="lang == 'ka'"
            v-html="properties.termsAndConditionKA"
          ></b-card-text>
          <b-card-text
            v-else
            v-html="properties.termsAndConditionEN"
          ></b-card-text>
          <b-card-text>
            <div class="annotation" v-if="properties.attachments.attachment">
              <!-- <button class="btn btn-primary" @click="downloadFile()"> -->
              <li v-html="properties.attachments.fileName"></li>

              <a
                style="color: black"
                target="_blank"
                download
                :href="`${server_url}/${properties.attachments.attachment}`"
              >
                <i style="font-size: 20px" class="fad fa-cloud-download"></i>
                {{ $t("medicamentsDescription.download") }}
              </a>
            </div></b-card-text
          >
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import env from "../../../../env.json";
export default {
  components: {},
  data() {
    return {
      text: "hi from",
      singlePorduct: null,
      server_url: env.server_url,
    };
  },
  props: ["generateDropList", "chapter_name", "item", "properties"],

  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.annotation {
  text-transform: uppercase;
  font-family: mtvaruli;

  text-align: center;
  flex-direction: column;
  justify-content: center;
}
::v-deep .btn-tab.focus,
.btn-tab:focus {
  background-color: white;
  border-color: white;
}
::v-deep .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
::v-deep .btn-primary:not(:disabled):not(.disabled):active {
  background-color: none;
  border-color: none;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #7e5493;
  border-color: white;
  box-shadow: none;
}
::v-deep .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e5493;
  border-color: #7e54939f;
}
.btn-primary {
  border-radius: 0px;
  background-color: #7e5493;
  font-size: 14px;

  margin: auto;
  border: none;
}
::v-deep .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e54939f;
  border-color: #7e54939f;
}
::v-deep .nav-item {
  background: #b3b3b3;
}
::v-deep .card-text:last-child {
  color: #4d4d4c;
}
::v-deep.tab-pane {
  background-color: #f6f4f5 !important;
  margin-top: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
}
::v-deep .nav-tabs .nav-link.active {
  color: #4d4d4c;
  background-color: #f6f4f5;

  box-shadow: none !important;
}

::v-deep .card-header-tabs a {
  margin: auto;
}

::v-deep .nav-tabs .nav-link {
  border-color: #e9ecef;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
::v-deep .card-header {
  border-bottom: 0px;
  padding: 0 0.65rem;
}

::v-deep a {
  color: white;
  font-family: mtavruli;
}

::v-deep .card {
  border: none;
}
.navbar {
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  max-width: 1400px;
  margin: auto;
  width: 100%;
}

nav {
  background-color: #f6f4f5;
}

.annotation a {
  color: #4d4d4c;
  font-size: 14px;
}
.annotation li {
  font-family: mtavruliBOLD;
  color: #7e5493;
}

li {
  text-decoration: none;
  color: #7e5493;
  list-style: none;
}
</style>
