<template>
  <div>
    <Navbar />
    <div class="medContainer">
      <div class="form-outline seacrh mt-3 mb-3">
        <input
          ref="inputSearch"
          @click="showModal()"
          type="search"
          id="form1"
          class="form-control"
          :placeholder="$t('front.search')"
        />
      </div>

      <b-modal
        v-model="modalShow"
        ok-only
        ok-variant="secondary"
        :ok-title="$t('front.close')"
      >
        <DetailModalCsr />
      </b-modal>
      <PathPage />

      <div style="background-color: #f6f4f5" class="mainCOnt">
        <div
          class="row align-items-center d-flex justify-content-around mx-auto"
          style="max-width: 1400px; margin: auto"
        >
          <div
            class="col-12 col-xl-5 col-lg-12 col-md-12 col-sm-12 pl-0 pr-0 mt-3"
          >
            <div
              class="
                gallerySide
                mr-0
                col-12
                d-flex
                mt-3
                d-flex
                justify-content-center
                pl-0
                pr-0
              "
              v-if="singlePorduct"
            >
              <Gallery :images="singlePorduct.images" />
            </div>
          </div>

          <div
            class="col-12 col-xl-6 col-lg-12 col-md-12 col-sm-12 pl-0"
            v-if="singlePorduct"
          >
            <div class="col-12 pr-0 align-items-center">
              <MedicamentsDescription
                :properties="singlePorduct.additionalInformation"
                :item="singlePorduct"
              />
            </div>
          </div>
          <div class="col-12 mt-5 mb-5 pl-0 pr-0">
            <div class="medicamentsDesc col pl-0 pr-0">
              <Descriptions :properties="singlePorduct" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import env from "../../../../env.json";
import Navbar from "../../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../../components/Footer/newFooter/Footer.vue";
import MedicamentsDescription from "./MedicamentsDescription.vue";
import Gallery from "./Gallery.vue";
import Descriptions from "./Descriptions.vue";
import PathPage from "../../PathPage.vue";
import DetailModalCsr from "./DetailModalCsr.vue";
import axios from "axios";

export default {
  components: {
    Navbar,
    Footer,
    PathPage,
    DetailModalCsr,
    MedicamentsDescription,
    Gallery,
    Descriptions,
  },

  data() {
    return {
      isLoading: false,
      total: 0,
      search: "",
      timer: null,
      lang: "ka",
      singleNews: null,
      server_url: env.server_url,
      items: [],
      list: [],
      timeout: 500,
      attachments: {},
      singlePorduct: null,

      modalShow: false,
    };
  },

  watch: {
    search: function (val) {
      clearTimeout(this.timer);
      if (val) {
        this.timer = setTimeout(() => {
          const valuedUrl = `${env.host}/search/products/all/null/${val}/null/null`;
          axios.get(valuedUrl).then((result) => {
            this.items = result.data.item;
            this.total = result.data.item.length;
          });
        }, this.timeout);
      } else {
        this.total = 0;
      }
    },
  },
  methods: {
    showModal: function () {
      this.modalShow = !this.modalShow;
    },
    handleNavigate: function (slug) {
      this.isLoading = true;
      axios
        .get(`${env.host}/get/once/products/${slug}/null/null`)
        .then((result) => {
          this.singlePorduct = {
            item: result.data.item,
          };
          this.isLoading = false;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.inputSearch.focus();
    }, 100);
    const url = window.location.href.split("/");
    if (
      url[3] == "medicaments" ||
      url[3] == "vitamins" ||
      url[3] == "care" ||
      url[3] == "proposal"
    ) {
      document.getElementById(url[3]).style.fontWeight = "bold";
    }
    axios
      .get(`${env.host}/get/once/products/${this.$route.params.slug}/null/null`)
      .then((result) => {
        this.singlePorduct = result.data.item;
      });
    // console.log(this.singlePorduct.attachments, "file");
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.mainCOnt {
  padding-bottom: 0px;

  bottom: 0px !important
  ;
}
.modalStyle {
  width: 100%;
}
.medContainer {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  min-height: calc(100vh - 190px);
}

.form-outline {
  max-width: 1400px;
  margin: auto;
}
.form-control {
  border-radius: 0px;
}
.form-control:focus {
  border-color: #7e5493;
  box-shadow: none;
}
::v-deep .btn-secondary {
  color: #fff;
  background-color: #a480b7 !important;
  border-color: #a480b7 !important;
  border-radius: 2px !important;
}

@media all and (max-width: 1500px) {
  .medContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media all and (max-width: 1199px) {
  .medicamentsDesc {
    margin-top: 20px;
  }
}
</style>
