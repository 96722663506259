<template>
  <div v-if="items.length > 0">
    <lingallery
      :id.sync="images._id"
      :width="width"
      :items="items"
      :height="height"
      :addons="{ enableLargeView: true }"
    >
    </lingallery>
  </div>
</template>

<script>
import Lingallery from "lingallery";
import env from "../../../../env.json";

export default {
  data() {
    return {
      width: 350,
      height: 350,
      items: [],
      server_url: env.server_url,
    };
  },
  props: ["images"],
  components: {
    Lingallery,
  },
  mounted() {
    this.items = this.images.map((image) => {
      return {
        src: `${env.server_url}/${image.image}`,
        thumbnail: `${env.server_url}/${image.image}`,
      };
    });
  },
};
</script>

<style scoped>
</style>
